.tooltip-arrow {
    border-top-color: #131b28 !important
}

.tooltip-inner {
    background: #131b28 !important;
    font-weight: bold !important
}

.header-splash {
    background: url("/assets/background.jpg") no-repeat 50%/cover;
    height: 583px;
    width: 100%;
    position: absolute;
    opacity: .3;
    -webkit-filter: blur(5px);
    filter: blur(5px)
}

.sidebar-round {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px
}

.close-mobile {
    display: none
}

* {
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

::-webkit-scrollbar {
    width: 8px
}

::-webkit-scrollbar-thumb {
    background: #232f44
}

::-webkit-scrollbar-track {
    background: #1e283a
}

p {
    margin: 0
}

.vote__flex {
    display: grid;
    grid-gap: 10px;
    display: -ms-grid;
    display: grid;
    grid-gap: 20px
}

@media only screen and (min-width: 1024px) {
    .vote__flex {
        -ms-grid-columns: minmax(240px, 320px) 1fr minmax(240px, 320px);
        grid-template-columns: minmax(240px, 320px) 1fr minmax(240px, 320px)
    }
}

.vote__flex .vote__col {
    background: #182232;
    padding: 20px;
    border-radius: 10px
}

.vote__flex .vote__col .vote__header .vote__heading {
    color: #ff9800;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase
}

.vote__flex .vote__col .vote__links li {
    list-style: none;
    margin-bottom: 10px
}

.vote__flex .vote__col .vote__links li a {
    text-decoration: none;
    padding: 20px;
    display: block;
    background: #131b28;
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    border-radius: 10px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.vote__flex .vote__col .vote__links li a span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: #acacaa
}

.vote__flex .vote__col .vote__links li a:hover {
    opacity: 0.8;
    color: #ff9800
}

.vote__flex .vote__col .vote__top--voters .__voter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border-top: 1px solid #1c283a
}

.vote__flex .vote__col .vote__top--voters .__voter .voter__name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.vote__flex .vote__col .vote__top--voters .__voter .voter__name .__place {
    margin-right: 20px;
    width: 54px
}

.vote__flex .vote__col .vote__top--voters .__voter .voter__name .voter__skin img {
    border-radius: 5px;
    margin-right: 10px
}

.vote__flex .vote__col .vote__top--voters .__voter .voter__name .voter__ign {
    margin-left: 10px
}

.vote__flex .vote__col .vote__top--voters .__voter:first-child {
    border-top: none !important
}

.vote__flex .vote__col .vote__informations {
    text-align: center
}

.__voter:nth-of-type(1) > .voter__name {
    color: #feca0c;
    font-weight: bold
}

.__voter:nth-of-type(2) > .voter__name {
    color: #bbb3b3;
    font-weight: bold
}

.__voter:nth-of-type(3) > .voter__name {
    color: #b59e70;
    font-weight: bold
}

.page__title {
    color: #fff;
    margin: 40px 0 20px 0;
    background: #182232;
    padding: 20px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 2px solid #ff9800;
    font-size: 20px
}

.rule {
    margin-bottom: 20px
}

.rule .rule__header {
    margin-bottom: 20px
}

.rule .rule__header .rule__header--heading {
    margin-bottom: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: bold
}

.rule .rules .rule__col {
    background: #182232;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px
}

body {
    background: #131b28 !important;
    font-size: 14px;
    color: #aeaeae;
    margin: 0;
    font-weight: 500;
    line-height: unset
}

.triplezone-wrapper {
    max-width: 1360px;
    margin: auto;
    padding: 0 10px;
    position: relative
}

#ipServer {
    cursor: context-menu;
    opacity: 0;
    outline: 0;
    border: none;
    position: fixed
}

#loader {
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.49);
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    border-radius: 0
}

#loader-giftcard {
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.74);
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    border-radius: 5px
}

.alerta-giftcard {
    margin: 10px 0 0;
    color: #fff;
    background: #4da564;
    padding: 10px 20px;
    color: #ffffff;
    display: inline-block;
    border-radius: 5px;
    display: none
}

.alerta-eroare {
    color: #d8000c !important;
    background-color: #ffd2d2 !important
}

.alerta-succes {
    color: #4f8a10 !important;
    background-color: #dff2bf !important
}

.loader-hide {
    display: none
}

.lds-ring {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.lds-ring div {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent
}

.lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
    animation-delay: -0.45s
}

.lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s
}

.lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s
}

@-webkit-keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

#modal {
    background: #1e283a
}

.flex__portal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media only screen and (min-width: 968px) {
    .flex__portal {
        margin: 80px 0 80px 0
    }
}

.flex__portal a {
    display: contents;
    text-decoration: none
}

.flex__portal .portal__link {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 15px);
    flex: 0 0 calc(100% - 15px);
    margin: 7.5px;
    background: #182232;
    padding: 20px;
    border-radius: 10px
}

.flex__portal .portal__link:hover img {
    -webkit-transform: scale(1.05) rotate(8deg);
    transform: scale(1.05) rotate(8deg)
}

@media only screen and (min-width: 968px) {
    .flex__portal .portal__link {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(25% - 15px);
        flex: 0 0 calc(25% - 15px)
    }
}

.flex__portal .portal__link img {
    max-width: clamp(11.8rem, 2.5vw, 2.8rem);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.flex__portal .portal__link p {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin-top: 10px
}
@media only screen and (max-width: 968px) {
    .flex__portal .portal__link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
    .flex__portal .portal__link p {
        margin: 0px 0px 0px 20px
    }
    .flex__portal .portal__link img {
        max-width: 64px
    }
}

.footer__navigation--bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media only screen and (max-width: 968px) {
    .footer__navigation--bar {
        margin-top: 10px
    }
}

.footer__navigation--bar a {
    margin: 0 10px;
    text-decoration: none;
    color: #b5b5b5;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.footer__navigation--bar a:hover {
    color: #fff
}

.footer__navigation--bar a i {
    margin-right: 5px;
    font-size: 25px
}

.footer__navigation--bar .discord__link {
    background: #7289DA;
    padding: 6px 15px;
    border-radius: 8px;
    color: #fff
}

@media only screen and (min-width: 968px) {
    .mobile__player--count {
        display: none !important
    }
}

@media only screen and (max-width: 968px) {
    .widget {
        display: none !important
    }
}

.mobile__player--count {
    text-align: center;
    margin: 10px 0
}

.mobile__player--count .player__count {
    text-transform: uppercase
}

.mobile__player--count .__title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 1px
}

@-webkit-keyframes scaleAnimation {
    0% {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes scaleAnimation {
    0% {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes drawCircle {
    0% {
        stroke-dashoffset: 151px
    }
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes drawCircle {
    0% {
        stroke-dashoffset: 151px
    }
    100% {
        stroke-dashoffset: 0
    }
}

@-webkit-keyframes drawCheck {
    0% {
        stroke-dashoffset: 36px
    }
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes drawCheck {
    0% {
        stroke-dashoffset: 36px
    }
    100% {
        stroke-dashoffset: 0
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.animated {
    display: none;
    position: absolute;
    left: 44%;
    top: 38%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

#successAnimationCircle {
    stroke-dasharray: 151px 151px;
    stroke: #dadadb
}

#successAnimationCheck {
    stroke-dasharray: 36px 36px;
    stroke: #dadadb
}

#successAnimationResult {
    fill: #dadadb;
    opacity: 0
}

#successAnimation.animated {
    -webkit-animation: 1s ease-out 0s 1 both scaleAnimation;
    animation: 1s ease-out 0s 1 both scaleAnimation
}

#successAnimation.animated #successAnimationCircle {
    -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut
}

#successAnimation.animated #successAnimationCheck {
    -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut
}

#successAnimation.animated #successAnimationResult {
    -webkit-animation: 0.3s linear 0.9s both fadeIn;
    animation: 0.3s linear 0.9s both fadeIn
}

@-webkit-keyframes glowitem {
    0% {
        -webkit-filter: drop-shadow(0px 0px 5px rgba(218, 218, 219, 0.5));
        filter: drop-shadow(0px 0px 5px rgba(218, 218, 219, 0.5))
    }
    100% {
        -webkit-filter: drop-shadow(0px 0px 10px rgba(218, 218, 219, 0.5));
        filter: drop-shadow(0px 0px 10px rgba(218, 218, 219, 0.5))
    }
}

@keyframes glowitem {
    0% {
        -webkit-filter: drop-shadow(0px 0px 5px rgba(218, 218, 219, 0.5));
        filter: drop-shadow(0px 0px 5px rgba(218, 218, 219, 0.5))
    }
    100% {
        -webkit-filter: drop-shadow(0px 0px 10px rgba(218, 218, 219, 0.5));
        filter: drop-shadow(0px 0px 10px rgba(218, 218, 219, 0.5))
    }
}

.payment--mod {
    text-align: center
}

.payment--mod svg {
    width: 64px;
    height: auto;
    margin: auto;
    display: block;
    margin-bottom: 10px
}

.payment--mod .payment-title {
    font-size: 16px;
    font-weight: bold;
    color: #fff
}

.triplezone-header {
    position: relative;
}

.triplezone-header .prioritate {
    position: relative;
    z-index: 10
}

.triplezone-header .top-bar {
    position: absolute;
    padding-top: 20px;
}

.triplezone-header .top-bar .back-btn {
    text-decoration: none;
    display: inline-block;
    color: rgba(255, 255, 255, 0.45);
    background: rgba(255, 255, 255, 0.15);
    font-weight: 700;
    padding: 12px 20px;
    border-radius: 40px;
    font-size: 14px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.triplezone-header .top-bar .back-btn span {
    display: none
}

.triplezone-header .top-bar .back-btn i {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.triplezone-header .top-bar .back-btn:hover {
    color: rgba(255, 255, 255, 0.75)
}

.triplezone-header .top-bar .back-btn:hover i {
    margin-right: 5px
}

.triplezone-header .top-bar .profile-avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto
}

.triplezone-header .top-bar .profile-avatar .profile-description {
    text-align: right;
    margin-right: 10px
}

.triplezone-header .top-bar .profile-avatar .profile-description p {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.triplezone-header .top-bar .profile-avatar .profile-description .profile-title {
    font-size: 16px;
    color: #fff;
    font-weight: 500
}

.triplezone-header .top-bar .profile-avatar .profile-description .profile-description-text {
    font-size: 12px;
    color: #ffcb5b;
    text-transform: uppercase;
    font-weight: 700
}

.triplezone-header .top-bar .profile-avatar .profile-head {
    position: relative
}

.triplezone-header .top-bar .profile-avatar .profile-head .helmet {
    position: absolute;
    top: -14px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 27px
}

.triplezone-header .top-bar .profile-avatar .profile-head .avatar-head {
    border-radius: 5px
}

.triplezone-header .top-bar .profile-avatar .profile-head .placeholder-items {
    position: absolute;
    right: -30px;
    top: -10px;
    background: #ffcb5b;
    color: #fff;
    border: 2px solid #fff;
    padding: 3px 6px;
    font-weight: bold;
    border-radius: 40px;
    display: none
}

.triplezone-header .top-bar .profile-avatar:hover p {
    margin-right: 5px
}

.triplezone-header .triplezone-header-g {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.triplezone-header .triplezone-header-g .widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: auto;
    padding: 10px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    cursor: pointer
}

.triplezone-header .triplezone-header-g .widget i {
    color: #dadadb;
    font-size: 50px
}

.triplezone-header .triplezone-header-g .widget .widget-icon {
    position: relative
}

.triplezone-header .triplezone-header-g .widget .widget-icon span {
    position: absolute;
    top: -31px;
    background: #1e283a;
    padding: 5px 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 40px;
    background: #dadadb;
    color: #131b28;
    font-weight: bold
}

.triplezone-header .triplezone-header-g .widget .description-widget {
    margin-left: 20px;
    letter-spacing: 1px
}

.triplezone-header .triplezone-header-g .widget .description-widget .widget-title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    color: #dadadb
}

.triplezone-header .triplezone-header-g .widget .description-widget .widget-description {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase
}

.triplezone-header .triplezone-header-g .widget:hover {
    -webkit-filter: drop-shadow(0px 0px 5px rgba(218, 218, 219, 0.5));
    filter: drop-shadow(0px 0px 5px rgba(218, 218, 219, 0.5))
}

.triplezone-header .triplezone-header-g .discord-widget p {
    text-align: right
}

.triplezone-header .triplezone-header-g .discord-widget .description-widget {
    margin-right: 20px;
    margin-left: 0
}

.triplezone-header .triplezone-header-g .minecraft-widget {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 10px
}

.triplezone-header .header-logo {
    position: relative;
    top: 0px
}

.triplezone-header .header-logo .header-logo-image {
    max-width: 450px
}

.triplezone-header .header-logo .logo-particles img {
    position: absolute;
    max-width: 64px;
    width: 32px
}

.triplezone-header .header-logo .logo-particles img:nth-child(1) {
    -webkit-animation: logo-particle-1 4.5s;
    animation: logo-particle-1 4.5s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    top: 0
}

.triplezone-header .header-logo .logo-particles img:nth-child(2) {
    top: 30px;
    right: 0;
    -webkit-animation: logo-particle-2 4.5s;
    animation: logo-particle-2 4.5s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

@-webkit-keyframes logo-particle-1 {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    to {
        width: 54px;
        -webkit-transform: translate3d(0, -25px, 0);
        transform: translate3d(0, -25px, 0)
    }
}

@keyframes logo-particle-1 {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    to {
        width: 54px;
        -webkit-transform: translate3d(0, -25px, 0);
        transform: translate3d(0, -25px, 0)
    }
}

@-webkit-keyframes logo-particle-2 {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    to {
        width: 64px;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }
}

@keyframes logo-particle-2 {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    to {
        width: 64px;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }
}

.triplezone__second--nav {
    background: #1e283a;
    border-radius: 5px;
    margin-top: 33px;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    display: none
}

.triplezone__second--nav .second__nav--list {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.triplezone__second--nav .second__nav--list .list__second--nav .list__second__category--link {
    padding: 35px 20px 35px 53px;
    display: block;
    color: #aeaeae;
    position: relative;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.triplezone__second--nav .second__nav--list .list__second--nav .list__second__category--link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    width: 35px;
    height: 100%;
    display: block;
    background-size: contain !important;
    background: url(https://i.imgur.com/gXPCyVX.png) no-repeat center !important;
    background-size: contain !important;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.triplezone__second--nav .second__nav--list .list__second--nav .list__second__category--link::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 27px;
    background: #ffffff1f;
    content: "";
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.triplezone__second--nav .second__nav--list .list__second--nav .list__second__category--link:hover {
    color: #fff
}

.triplezone__second--nav .second__nav--list .list__second--nav .list__second__category--link:hover::before {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.triplezone__second--nav .second__nav--list .list__second--nav:first-child .list__second__category--link::after {
    display: none
}

.triplezone__second--nav .second__nav--list .triplezone__second--dropdown {
    list-style: none;
    position: absolute;
    background: #232f44;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 10px 0 0 0;
    z-index: 1;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    display: none
}

.triplezone__second--nav .second__nav--list .triplezone__second--dropdown li a {
    color: inherit;
    text-decoration: none;
    padding: 20px;
    display: block;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    border-bottom: 1px solid #ffffff0a
}

.triplezone__second--nav .second__nav--list .triplezone__second--dropdown li a:hover {
    color: #fff
}

.triplezone__second--nav .second__nav--list .triplezone__second--dropdown li:last-child a {
    border-bottom: none
}

.triplezone__second--nav .rotire-carret {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.triplezone__second--nav i {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    font-size: 20px;
    display: inline-block
}

.active-dropdown .list__second__category--link {
    color: #ffcb5b !important
}

.gift {
    position: absolute;
    top: -21px;
    width: 40px;
    height: 40px;
    background: #dadadb;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.gift:hover i {
    color: #fff
}

.gift i {
    font-size: 20px;
    color: #ffffff87;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.iziToast>.iziToast-body .iziToast-texts {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.__disabled--button {
    opacity: 0.5;
    pointer-events: none
}

.category__list .category__list--item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #232f44;
    padding: 15px 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #0000001f;
    margin-bottom: 10px
}

.category__list .category__list--item .category__list__item--title p {
    font-size: 16px
}

.category__list .category__list--item .category__list__item--title .countdown__title {
    color: #fe3c45
}

.category__list .category__list--item .category__list__item--price {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.category__list .category__list--item .category__list__item--price p {
    color: #dadadb;
    font-weight: bold
}

.category__list .category__list--item .category__list__item--price p del {
    font-size: 12px;
    color: #fe3c45;
    font-weight: 500
}

.category__list .category__list--item .category__list__item--buttons {
    margin-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.category__list .category__list--item .category__list__item--buttons .buy__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    width: 120px;
    margin: auto;
    background: #4da564;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.category__list .category__list--item .category__list__item--buttons .buy__btn:hover {
    background: #48995d
}

.category__list .category__list--item .category__list__item--buttons .info__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
    background: #2196f3;
    width: auto;
    margin-right: 5px
}

.category__list .category__list--item .category__list__item--buttons .info__btn:hover {
    background: #218bdf
}

.category__list .category__list--item .category__list__item--buttons .remove__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    width: 120px;
    margin: auto;
    background: #fe3c45 !important;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.category__list .category__list--item .category__list__item--buttons .remove__btn:hover {
    background: #d9373f !important
}

.category__list .category__list--item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #232f44;
    padding: 15px 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.category__list .category__list--item .category__list__item--title p {
    font-size: 16px
}

.category__list .category__list--item .category__list__item--title .countdown__title {
    color: #fe3c45
}

.category__list .category__list--item .category__list__item--price {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.category__list .category__list--item .category__list__item--price p {
    color: #dadadb;
    font-weight: bold
}

.category__list .category__list--item .category__list__item--price p del {
    font-size: 12px;
    color: #fe3c45;
    font-weight: 500
}

.category__list .category__list--item .category__list__item--buttons {
    margin-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.category__list .category__list--item .category__list__item--buttons .buy__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    width: 120px;
    margin: auto;
    background: #4da564;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.category__list .category__list--item .category__list__item--buttons .buy__btn:hover {
    background: #48995d
}

.category__list .category__list--item .category__list__item--buttons .info__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
    background: #2196f3;
    width: auto;
    margin-right: 5px
}

.category__list .category__list--item .category__list__item--buttons .info__btn:hover {
    background: #218bdf
}

.category__list .category__list--item .category__list__item--buttons .remove__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    width: 120px;
    margin: auto;
    background: #fe3c45 !important;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.category__list .category__list--item .category__list__item--buttons .remove__btn:hover {
    background: #d9373f !important
}

.category__list--with--images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
    padding: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.category__list--with--images .countdown__title {
    background: #fe3c45;
    color: #fff;
    padding: 5px 10px;
    border-radius: 40px;
    position: absolute;
    top: -12px;
    left: 61px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.category__list--with--images .category__list--item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(25% - 15px);
    flex: 0 0 calc(25% - 15px);
    margin: 7.5px;
    background: #232f44;
    border-radius: 5px;
    border: 1px solid #2b3a54;
    padding: 20px;
    position: relative
}

.category__list--with--images .category__list--item .category__list__item--title img {
    margin: auto;
    display: block;
    max-width: 165px;
    max-height: 143px;
    cursor: pointer
}

.category__list--with--images .category__list--item .category__list__item--details {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.category__list--with--images .category__list--item .category__list__item--details .__item--title {
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin: 10px 0
}

.category__list--with--images .category__list--item .category__list__item--details .__item__price--list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.category__list--with--images .category__list--item .category__list__item--details .__item__price--list .__item--discount {
    font-size: 12px;
    color: #fe3c45;
    margin-right: 5px
}

.category__list--with--images .category__list--item .category__list__item--details .__item__price--list .__item--price {
    display: inline-block;
    color: #ffcb5b;
    font-weight: bold;
    font-size: 14px
}

.category__list--with--images .category__list--item .category__list__item--buttons {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.category__list--with--images .category__list--item .category__list__item--buttons .buy__btn {
    font-weight: bold;
    padding: 8px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #4da564;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.category__list--with--images .category__list--item .category__list__item--buttons .buy__btn:hover {
    background: #48995d
}

.category__list--with--images .category__list--item .category__list__item--buttons .remove__btn {
    font-weight: bold;
    padding: 8px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #fe3c45 !important;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.category__list--with--images .category__list--item .category__list__item--buttons .remove__btn:hover {
    background: #d9373f !important
}

.category__list--with--images .category__list--item .category__list__item--buttons .info__btn {
    font-weight: bold;
    padding: 8px 13px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
    background: #2196f3;
    width: auto;
    margin-right: 10px
}

.category__list--with--images .category__list--item .category__list__item--buttons .info__btn:hover {
    background: #218bdf
}

.category__list--with--images .upgrade--icon {
    background: none !important;
    border: none !important;
    text-align: center
}

.category__list--with--images .upgrade--icon i {
    font-size: 84px;
    display: inline-block;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: -webkit-gradient(linear, left top, left bottom, from(#e72c83), to(#a742c6));
    background: linear-gradient(to bottom, #e72c83 0%, #a742c6 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.category__list--with--images .hided__upgrade--icon {
    opacity: 0.5
}

.category__list--with--images .hided__upgrade {
    display: none
}

.category__list--with--images .category__list--upgrade {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.33% - 15px);
    flex: 0 0 calc(33.33% - 15px);
    margin: 7.5px;
    background: #232f44;
    border-radius: 5px;
    border: 1px solid #2b3a54;
    padding: 0;
    padding-top: 10px;
    position: relative
}

.category__list--with--images .category__list--upgrade .category__list__item--title img {
    margin: auto;
    display: block;
    max-width: 120px;
    cursor: pointer
}

.category__list--with--images .category__list--upgrade .category__list__item--details {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.category__list--with--images .category__list--upgrade .category__list__item--details .__item--title {
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin: 10px 0
}

.category__list--with--images .category__list--upgrade .category__list__item--details .__item__price--list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: 10px;
    background: #2b3a54;
    padding: 5px 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1)
}

.category__list--with--images .category__list--upgrade .category__list__item--details .__item__price--list .__item--discount {
    font-size: 12px;
    color: #fe3c45;
    margin-right: 5px
}

.category__list--with--images .category__list--upgrade .category__list__item--details .__item__price--list .__item--price {
    display: inline-block;
    color: #dadadb;
    font-weight: bold;
    font-size: 14px
}

.category__list--with--images .category__list--upgrade .category__list__item--buttons {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.category__list--with--images .category__list--upgrade .category__list__item--buttons .buy__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #4da564;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.category__list--with--images .category__list--upgrade .category__list__item--buttons .buy__btn:hover {
    background: #48995d
}

.category__list--with--images .category__list--upgrade .category__list__item--buttons .remove__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #fe3c45 !important;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.category__list--with--images .category__list--upgrade .category__list__item--buttons .remove__btn:hover {
    background: #d9373f !important
}

.category__list--with--images .category__list--upgrade .category__list__item--buttons .info__btn {
    font-weight: bold;
    padding: 5px 13px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
    background: #2196f3;
    width: auto;
    margin-right: 10px
}

.category__list--with--images .category__list--upgrade .category__list__item--buttons .info__btn:hover {
    background: #218bdf
}

.change__data__upgrade--package {
    display: none;
    cursor: pointer
}

p.upgrade__category--title {
    font-weight: bold;
    font-size: 16px;
    color: #fff
}

#ranks-upgrades {
    background: #1e283a
}

#ranks-upgrades .rank-selected {
    background: #3c5558 !important;
    border: 1px solid #dadadb !important
}

#ranks-upgrades .ranks--body {
    padding: 20px
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
    padding: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.33% - 15px);
    flex: 0 0 calc(33.33% - 15px);
    margin: 7.5px;
    background: #232f44;
    border-radius: 5px;
    border: 1px solid #2b3a54;
    padding: 0;
    padding-top: 10px;
    position: relative
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--title img {
    margin: auto;
    display: block;
    max-width: 120px;
    cursor: pointer
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--details {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--details .__item--title {
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin: 10px 0
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--details .__item__price--list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: 10px;
    background: #2b3a54;
    padding: 5px 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1)
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--details .__item__price--list .__item--discount {
    font-size: 12px;
    color: #fe3c45;
    margin-right: 5px
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--details .__item__price--list .__item--price {
    display: inline-block;
    color: #dadadb;
    font-weight: bold;
    font-size: 14px
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .buy__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #4da564;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .buy__btn:hover {
    background: #48995d
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .remove__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #fe3c45 !important;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .remove__btn:hover {
    background: #d9373f !important
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .info__btn {
    font-weight: bold;
    padding: 5px 13px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
    background: #2196f3;
    width: auto;
    margin-right: 10px
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .info__btn:hover {
    background: #218bdf
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .buy__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #4da564;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .buy__btn:hover {
    background: #48995d
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .remove__btn {
    font-weight: bold;
    padding: 5px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #fe3c45 !important;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .remove__btn:hover {
    background: #d9373f !important
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .info__btn {
    font-weight: bold;
    padding: 5px 13px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
    background: #2196f3;
    width: auto;
    margin-right: 10px
}

#ranks-upgrades .ranks--body .ranks__upgrades--flex .category__list--upgrade .category__list__item--buttons .info__btn:hover {
    background: #218bdf
}

.upgrade--btn {
    font-weight: bold;
    padding: 20px 10px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 5px;
    margin: auto;
    background: #4da564;
    border: none;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-transform: uppercase;
    outline: none;
    display: block;
    text-align: center;
    text-decoration: none !important
}

.upgrade--btn:hover {
    background: #48995d
}

.upgrade__btn--disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5
}

.no__choosen--upgrade {
    height: 157px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    cursor: pointer
}

.no__choosen--upgrade .category__list__item--title p:first-child {
    font-size: 16px;
    font-weight: bold;
    color: #dadadb
}

.no__choosen--upgrade .category__list__item--title p:nth-child(2) {
    text-transform: uppercase;
    font-size: 12px
}

.mobile-enabled {
    display: block !important
}

.mobile-icon {
    font-size: 26px;
    vertical-align: middle;
    margin-right: 10px;
    -webkit-filter: drop-shadow(0px 0px 5px #dadadb);
    filter: drop-shadow(0px 0px 5px #dadadb)
}

.swal-modal {
    padding: 20px !important;
    background: #232f44 !important
}

.swal-footer .swal-button {
    background-color: #dadadb !important;
    border-radius: 5px !important;
    padding: 12px 50px !important;
    border-bottom: 3px solid rgba(0, 0, 0, 0.08);
    outline: none !important
}

.swal-footer {
    text-align: center
}

.swal-title:not(:last-child) {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 900 !important;
    letter-spacing: 1.5px;
    color: #fff !important
}

.swal-text {
    color: #aeaeae !important
}

.body--with-sidebar,
.checkout__page {
    display: -ms-grid;
    display: grid;
    margin-top: 40px
}

.body--with-sidebar img,
.checkout__page img {
    max-width: 100%
}

.body--with-sidebar .triplezone-sidebar,
.checkout__page .triplezone-sidebar {
    margin-bottom: 20px
}

.body--with-sidebar .triplezone-sidebar .chains,
.checkout__page .triplezone-sidebar .chains {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly
}

.body--with-sidebar .triplezone-sidebar .chains img,
.checkout__page .triplezone-sidebar .chains img {
    max-width: 14px
}

.body--with-sidebar .triplezone-sidebar .sidebar-header,
.checkout__page .triplezone-sidebar .sidebar-header {
    background: #1e283a;
    padding: 30px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    position: relative
}

.body--with-sidebar .triplezone-sidebar .sidebar-header p,
.checkout__page .triplezone-sidebar .sidebar-header p {
    color: #dadadb;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav,
.checkout__page .triplezone-sidebar .sidebar-nav {
    background: #1e283a;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    display: none
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav .link__active,
.checkout__page .triplezone-sidebar .sidebar-nav .link__active {
    border-left: 4px solid #ffcb5b;
    color: #ffcb5b !important;
    background: #ffcb5b;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 203, 91, 0.15)), color-stop(35%, #232f44)) !important;
    background: linear-gradient(90deg, rgba(255, 203, 91, 0.15) 0%, #232f44 35%) !important;
    overflow: hidden
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav .link__active::after,
.checkout__page .triplezone-sidebar .sidebar-nav .link__active::after {
    height: 50px;
    content: "";
    position: absolute;
    left: -49px;
    width: 50px;
    background: #ffcb5b;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg)
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav .toggle-drop-active,
.checkout__page .triplezone-sidebar .sidebar-nav .toggle-drop-active {
    background: #232f44
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav .active-dropdown,
.checkout__page .triplezone-sidebar .sidebar-nav .active-dropdown {
    color: #ffcb5b;
    background: #232f44
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav .rotire-carret,
.checkout__page .triplezone-sidebar .sidebar-nav .rotire-carret {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul,
.checkout__page .triplezone-sidebar .sidebar-nav ul {
    list-style-type: none
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li,
.checkout__page .triplezone-sidebar .sidebar-nav ul li {
    border-top: 1px solid #232f44;
    position: relative
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li .triplezone-dropdown,
.checkout__page .triplezone-sidebar .sidebar-nav ul li .triplezone-dropdown {
    background: #232f44;
    display: none
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li .triplezone-dropdown li,
.checkout__page .triplezone-sidebar .sidebar-nav ul li .triplezone-dropdown li {
    display: block;
    border-top: none
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li .triplezone-dropdown li a,
.checkout__page .triplezone-sidebar .sidebar-nav ul li .triplezone-dropdown li a {
    display: block;
    text-decoration: none;
    padding: 15px 20px;
    font-size: 14px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li .triplezone-dropdown li:hover a,
.checkout__page .triplezone-sidebar .sidebar-nav ul li .triplezone-dropdown li:hover a {
    margin-left: 5px
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li a,
.checkout__page .triplezone-sidebar .sidebar-nav ul li a {
    display: block;
    text-decoration: none;
    color: #aeaeae;
    padding: 30px 20px;
    padding-left: 90px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    letter-spacing: 1px
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li a .rotire-carret,
.checkout__page .triplezone-sidebar .sidebar-nav ul li a .rotire-carret {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li a i,
.checkout__page .triplezone-sidebar .sidebar-nav ul li a i {
    float: right;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    font-size: 20px
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li a:hover,
.checkout__page .triplezone-sidebar .sidebar-nav ul li a:hover {
    color: #fff
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li:last-child,
.checkout__page .triplezone-sidebar .sidebar-nav ul li:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul li:last-child .triplezone-dropdown,
.checkout__page .triplezone-sidebar .sidebar-nav ul li:last-child .triplezone-dropdown {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav ul:nth-child(1),
.checkout__page .triplezone-sidebar .sidebar-nav ul:nth-child(1) {
    display: block
}

.body--with-sidebar .triplezone-sidebar .sidebar-nav li a:before,
.checkout__page .triplezone-sidebar .sidebar-nav li a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    width: 64px;
    height: 100%;
    display: block;
    background-size: contain !important
}

.body--with-sidebar .triplezone-main-content,
.checkout__page .triplezone-main-content {
    position: relative
}

.body--with-sidebar .triplezone-main-content .title-category,
.checkout__page .triplezone-main-content .title-category {
    background: #1e283a;
    padding: 20px;
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1)
}

.body--with-sidebar .triplezone-main-content .title-category .category-title,
.checkout__page .triplezone-main-content .title-category .category-title {
    font-weight: bold;
    color: #dadadb;
    font-size: 24px
}

.body--with-sidebar .triplezone-main-content .description-category,
.checkout__page .triplezone-main-content .description-category {
    background: #1e283a;
    padding: 20px;
    padding-top: 0;
    border-radius: 0 0 5px 5px;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px
}

.body--with-sidebar .triplezone-main-content .body-object,
.checkout__page .triplezone-main-content .body-object {
    background: #1e283a;
    padding: 20px;
    margin: 20px 0;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1)
}

.body--with-sidebar .triplezone-main-content .triplezone-body-description,
.checkout__page .triplezone-main-content .triplezone-body-description {
    background: #1e283a;
    padding: 20px;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1)
}

.body--with-sidebar .triplezone-main-content .triplezone-body-description .body-obj,
.checkout__page .triplezone-main-content .triplezone-body-description .body-obj {
    margin: 20px 0
}

.body--with-sidebar .triplezone-main-content .giftcard-button,
.checkout__page .triplezone-main-content .giftcard-button {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    text-decoration: none;
    color: #ffffff;
    display: inline-block;
    margin-right: 10px;
    background: #dadadb;
    padding: 6px 15px;
    font-weight: 700;
    border-bottom: 3px solid rgba(0, 0, 0, 0.08);
    margin-top: 10px;
    margin-bottom: 10px;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1)
}

.body--with-sidebar .triplezone-main-content .giftcard-button i,
.checkout__page .triplezone-main-content .giftcard-button i {
    font-size: 22px;
    vertical-align: middle
}

.body--with-sidebar .triplezone-main-content .announcement,
.checkout__page .triplezone-main-content .announcement {
    background: #dadadb;
    padding: 15px 20px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative
}

.body--with-sidebar .triplezone-main-content .announcement p,
.checkout__page .triplezone-main-content .announcement p {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin: 0
}

.body--with-sidebar .triplezone-main-content .announcement p i,
.checkout__page .triplezone-main-content .announcement p i {
    font-size: 22px;
    vertical-align: middle;
    margin-right: 15px
}

.body--with-sidebar .triplezone-main-content .announcement:before,
.checkout__page .triplezone-main-content .announcement:before {
    content: "";
    position: absolute;
    display: block;
    top: 99%;
    left: 20px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-top-color: #dadadb
}

.body--with-sidebar .triplezone-main-content .home-splash,
.checkout__page .triplezone-main-content .home-splash {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px
}

.body--with-sidebar .triplezone-main-content .home-splash .splash__goal,
.checkout__page .triplezone-main-content .home-splash .splash__goal {
    width: 100%;
    background: #232f44;
    border-radius: 5px
}

.body--with-sidebar .triplezone-main-content .home-splash .splash__goal .splash__goal--body,
.checkout__page .triplezone-main-content .home-splash .splash__goal .splash__goal--body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.body--with-sidebar .triplezone-main-content .home-splash .splash__goal .splash__goal--body .goal__title,
.checkout__page .triplezone-main-content .home-splash .splash__goal .splash__goal--body .goal__title {
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase
}

.body--with-sidebar .triplezone-main-content .home-splash .splash__goal .splash__goal--body .goal__progress,
.checkout__page .triplezone-main-content .home-splash .splash__goal .splash__goal--body .goal__progress {
    color: #dadadb;
    font-weight: bold
}

.body--with-sidebar .triplezone-main-content .home-splash .splash__goal .splash__goal--footer,
.checkout__page .triplezone-main-content .home-splash .splash__goal .splash__goal--footer {
    height: 10px;
    background: #131b28;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.body--with-sidebar .triplezone-main-content .home-splash .splash__goal .splash__goal--footer .splash__goal__footer--progress,
.checkout__page .triplezone-main-content .home-splash .splash__goal .splash__goal--footer .splash__goal__footer--progress {
    background: #dadadb;
    position: absolute;
    width: 0%;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    height: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.body--with-sidebar .triplezone-main-content .home-splash p,
.checkout__page .triplezone-main-content .home-splash p {
    margin: 0
}

.body--with-sidebar .triplezone-main-content .home-splash .welcome-s,
.checkout__page .triplezone-main-content .home-splash .welcome-s {
    width: 100%;
    margin-bottom: 10px
}

.body--with-sidebar .triplezone-main-content .home-splash .welcome-s p,
.checkout__page .triplezone-main-content .home-splash .welcome-s p {
    font-weight: bold;
    text-transform: uppercase
}

.body--with-sidebar .triplezone-main-content .home-splash .welcome-s p:nth-child(1),
.checkout__page .triplezone-main-content .home-splash .welcome-s p:nth-child(1) {
    font-size: 12px;
    color: #ffcb5b
}

.body--with-sidebar .triplezone-main-content .home-splash .welcome-s p:nth-child(2),
.checkout__page .triplezone-main-content .home-splash .welcome-s p:nth-child(2) {
    color: #dadadb;
    font-size: 22px;
    letter-spacing: 2px;
    background: -webkit-linear-gradient(#ffcb5b, #dadadb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.body--with-sidebar .triplezone-main-content .home-splash .featured-item-s,
.checkout__page .triplezone-main-content .home-splash .featured-item-s {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer
}

.body--with-sidebar .triplezone-main-content .home-splash .featured-item-s .featured-item-description .featured-title,
.checkout__page .triplezone-main-content .home-splash .featured-item-s .featured-item-description .featured-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    opacity: 1;
    color: #dadadb;
    -webkit-filter: drop-shadow(0px 0px 5px #da3ae9);
    filter: drop-shadow(0px 0px 5px #da3ae9);
    margin-bottom: 5px
}

.body--with-sidebar .triplezone-main-content .home-splash .featured-item-s .featured-item-description .featured-item-title,
.checkout__page .triplezone-main-content .home-splash .featured-item-s .featured-item-description .featured-item-title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold
}

.body--with-sidebar .triplezone-main-content .home-splash .featured-item-s .featured-item-description .featured-item-price,
.checkout__page .triplezone-main-content .home-splash .featured-item-s .featured-item-description .featured-item-price {
    color: #4da564;
    font-weight: bold;
    font-size: 12px
}

.body--with-sidebar .triplezone-main-content .home-splash .featured-item-s .featured-item-description .featured-item-price del,
.checkout__page .triplezone-main-content .home-splash .featured-item-s .featured-item-description .featured-item-price del {
    color: #fe3c45
}

.body--with-sidebar .triplezone-main-content .home-splash .featured-item-s .featured-item-description a,
.checkout__page .triplezone-main-content .home-splash .featured-item-s .featured-item-description a {
    padding: 5px 10px;
    color: #fff;
    border: none;
    margin-right: auto;
    background: #4da564;
    outline: none;
    display: inline-block;
    text-decoration: none;
    margin-top: 5px;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.08);
    text-transform: uppercase
}

.body--with-sidebar .triplezone-main-content .home-splash .featured-item-s img,
.checkout__page .triplezone-main-content .home-splash .featured-item-s img {
    max-width: 128px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out
}

.body--with-sidebar .triplezone-main-content .home-splash .featured-item-s:hover img,
.checkout__page .triplezone-main-content .home-splash .featured-item-s:hover img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-filter: drop-shadow(0px 0px 5px rgba(218, 58, 233, 0.5));
    filter: drop-shadow(0px 0px 5px rgba(218, 58, 233, 0.5))
}

.body--with-sidebar .triplezone-main-content p,
.checkout__page .triplezone-main-content p {
    margin: 0;
    line-height: 1.5
}

.body--with-sidebar .triplezone-main-content legend,
.checkout__page .triplezone-main-content legend {
    padding: 0 20px;
    color: #4da564;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px
}

.body--with-sidebar .triplezone-main-content .support,
.checkout__page .triplezone-main-content .support {
    color: #4da564
}

.body--with-sidebar .triplezone-main-content .refund,
.checkout__page .triplezone-main-content .refund {
    color: #fe3c45
}

.body--with-sidebar .triplezone-main-content .support-button,
.checkout__page .triplezone-main-content .support-button {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #4da564;
    padding: 0 19px;
    height: 37px;
    line-height: 37px;
    font-weight: 700;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1)
}

.body--with-sidebar .triplezone-main-content .support-button i,
.checkout__page .triplezone-main-content .support-button i {
    font-size: 20px;
    vertical-align: middle
}

.body--with-sidebar .triplezone-main-content .support-button:hover,
.checkout__page .triplezone-main-content .support-button:hover {
    color: #fff;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1)
}

.body--with-sidebar .triplezone-main-content .terms-button,
.checkout__page .triplezone-main-content .terms-button {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #fe3c45;
    padding: 0 19px;
    height: 37px;
    line-height: 37px;
    font-weight: 700;
    border-bottom: 3px solid rgba(0, 0, 0, 0.08);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1)
}

.body--with-sidebar .triplezone-main-content .terms-button i,
.checkout__page .triplezone-main-content .terms-button i {
    font-size: 20px;
    vertical-align: middle
}

.body--with-sidebar .triplezone-main-content .terms-button:hover,
.checkout__page .triplezone-main-content .terms-button:hover {
    color: #fff;
    -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1)
}

.body--with-sidebar .title-of-subject,
.checkout__page .title-of-subject {
    color: #ffffff;
    font-weight: 900;
    letter-spacing: 0.8px;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px !important
}

.body--with-sidebar .giftcard-widget .giftcard-box,
.checkout__page .giftcard-widget .giftcard-box {
    position: relative
}

.body--with-sidebar .giftcard-widget .giftcard-box i,
.checkout__page .giftcard-widget .giftcard-box i {
    position: absolute;
    padding: 8px 12px;
    pointer-events: none;
    color: #ffcb5b;
    font-size: 25px
}

.body--with-sidebar .giftcard-widget .giftcard-box input,
.checkout__page .giftcard-widget .giftcard-box input {
    border: none;
    background: #232f44;
    padding: 13px;
    outline: none;
    border-radius: 5px;
    padding-left: 51px;
    max-width: 320px;
    width: 300px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #aeaeae
}

.body--with-sidebar .giftcard-widget .giftcard-box button,
.checkout__page .giftcard-widget .giftcard-box button {
    background: #4da564;
    padding: 13px 20px;
    outline: none;
    border: none;
    border-bottom: 3px solid rgba(0, 0, 0, 0.08);
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out
}

.footer-triplezone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 20px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center
}

.footer-triplezone .footer-copyright p {
    margin: 0
}

.footer-triplezone .footer-copyright .copyright-title {
    color: #aeaeae;
    font-weight: bold
}

.footer-triplezone .footer-copyright .copyright-subtitle {
    font-size: 12px;
    opacity: 0.5
}

.footer-triplezone .designed-by path {
    stroke: transparent;
    fill: #aeaeae
}

.footer-triplezone .designed-by {
    margin-top: 10px
}

@media only screen and (max-width: 1024px) {
    .category__list--item {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 calc(33.33% - 15px) !important;
        flex: 0 0 calc(33.33% - 15px) !important
    }
}

@media only screen and (max-width: 500px) {
    .category__list--item {
        -webkit-box-flex: 100% !important;
        -ms-flex: 100% !important;
        flex: 100% !important
    }
    .header-logo-image {
        max-width: 320px !important
    }
}

@media only screen and (min-width: 968px) {
    .body--with-sidebar {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: minmax(240px, 320px) 1fr;
        grid-template-columns: minmax(240px, 320px) 1fr;
        grid-gap: 20px
    }
    .triplezone-sidebar {
        margin-bottom: 0 !important
    }
    .triplezone-header-g {
        -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important;
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }
    .header-logo {
        top: 30px !important
    }
    .widget.minecraft-widget {
        -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
        order: 0 !important;
        margin-top: auto !important
    }
    .back-btn span {
        display: inline-block !important
    }
    .home-splash {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }
    .placeholder-items {
        display: block !important
    }
    .triplezone-modules {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }
    .sidebar-nav {
        display: block !important
    }
    .sidebar-header {
        cursor: auto !important
    }
    .sidebar-header p {
        text-align: center !important
    }
    .home-splash .splash__goal {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 calc(40% - 15px) !important;
        flex: 0 0 calc(40% - 15px) !important
    }
    .home-splash .welcome-s {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 calc(60% - 15px) !important;
        flex: 0 0 calc(60% - 15px) !important;
        margin: 0 !important
    }
    .mobile-icon {
        display: none !important
    }
    .recent-payments {
        margin-top: 0 !important
    }
    .footer-triplezone {
        text-align: unset !important;
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
        margin: 40px 0 !important
    }
    .designed-by {
        margin-top: 0px !important
    }
    .sidebar-round {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }
    .checkout__page {
        -ms-grid-columns: minmax(240px, 320px) 1fr;
        grid-template-columns: minmax(240px, 320px) 1fr;
        grid-gap: 20px;
        margin-bottom: 0 !important
    }
    .checkout__page--sidebar {
        margin-bottom: 0 !important
    }
    .checkout__form {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 calc(33.33% - 15px) !important;
        flex: 0 0 calc(33.33% - 15px) !important
    }
}
